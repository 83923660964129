<template>
  <div class="">
    <div class="row blue-underline mb-3">
      <div  @click="$router.push('/dpc-providers/solutions')" role="button" class="col-md-12 mb-4 ml-4 mt-4 font-weight-bold text-gray d-flex">
        <svg
        @click="$router.go(-1)"
          class="mr-2 mt-1"
          width="14"
          height="16"
          viewBox="0 0 14 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p  @click="$router.go(-1)">Back to Solution</p>
      </div>
    </div>
    <div>
      <div class="row form-row-height d-flex pt-2 pb-2 light-blue-text">
        <div class="col-md-4 ml-auto d-flex font-weight-bold">
          <div class=" mb-1 mt-1">
              <v-card
              outlined color="transparent"
              height="fit-content"
              width="270px"
              class=" mb-5 mt-2"
              :class="{ 'no-pointer': disablePointer }"
            >
              <div class="display pl-1 pr-1 pb-4">
               
                <div class="text-start justify-start mb-1 mt-0">
                  <img
                    :src="solution.logo ? solution.logo : '/tpa/virtualNetwork.svg'"
                    :width="solution.logo ? '170' : 'fit-content'"
                    class="mb-0"
                    height="60"
                  />
                </div>
                <span class="text-start mt-2 mb-0">
                  <p class="font-18 mb-0">
                    {{ solution.name }}
                  </p>
                </span>
                <span class="text-start mb-0">
                  <p class="font-18 text-gray">Address</p>
                </span>
                <div class="justify-start d-flex mb-0">
                  <v-chip
                  class="pa-2"
                  color="#AAC9F2"
                  text-color="#333333"
                >
                Virtual Network
                </v-chip>
                </div>
              </div>
            </v-card>
            <div class="pa-0">
               <v-btn v-if="!solution.contracted"  width="300"  class="primary status-size" @click="participateSolution(solution)">
                     Participate
               </v-btn>
               <v-btn  v-else-if="solution.participation.status == 'Pending Approval'" width="300" class="status-size pending" color="#E6E6E6">
                     Pending Approval
                </v-btn>
                <v-btn  v-else-if="solution.contracted && solution.participation.status == 'Active'" width="300"  class="status-size text--white" color="#D90101" @click="leaveSolution(item)">
                    Stop Participating
               </v-btn>
            </div>
          </div>
          <div align="start" class="mb-0 mt-1">  
            <img v-if="solution.favorite" @click="favorite()" src="/svg/filled-heart.svg"/>
            <img v-else  @click="favorite()" src="/svg/empty-heart.svg"/>
           </div>
        </div>
        <div class="col-md-7 col-11 ml-2 mr-auto">
         <v-expansion-panels  multiple  v-model.trim="panel" class="col-5">
              <v-expansion-panel class="mb-3" >
              <v-expansion-panel-header>
                  Details
                </v-expansion-panel-header>
                <v-expansion-panel-content class="col-12">
                  <v-divider horizontal class="mb-2"> </v-divider>
                  <div class="col-12 font-weight-bold mt-2 pb-0"> 
                    Description: 
                  </div>
                  <div class="col-12 text-gray"> 
                    {{ solution.details?.description ? solution.details.description : '/' }}
                  </div>
                  <div class="col-12 font-weight-bold mt-0 pb-0"> 
                    Definition: 
                  </div>
                  <div class="col-12 text-gray mt-0"> 
                    {{ solution.details?.definition ? solution.details.definition : '/' }}
                  </div>
                  <div class="col-12 mt-0 font-weight-bold"> 
                    <a @click="openUrl()"> Visit Website
                       <svg  class="ml-1 pt-1" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.68866 10.9703C5.57151 10.8532 5.57151 10.6632 5.68866 10.546L13.7674 2.46728L13.7497 2.44961L11.5008 2.45816C11.3351 2.45816 11.2008 2.32383 11.2008 2.15816V1.90818C11.2008 1.74248 11.3351 1.60818 11.5008 1.60818L14.9008 1.59961C15.0665 1.59961 15.2008 1.73391 15.2008 1.89961L15.1922 5.29961C15.1922 5.46528 15.0579 5.59961 14.8922 5.59961H14.6422C14.4766 5.59961 14.3422 5.46528 14.3422 5.29961L14.3508 3.05066L14.3331 3.03298L6.25436 11.1117C6.13721 11.2289 5.94726 11.2289 5.83008 11.1117L5.68866 10.9703ZM11.4887 7.07948L11.2887 7.27948C11.2324 7.33574 11.2008 7.41205 11.2008 7.49161V13.1996C11.2008 13.4205 11.0217 13.5996 10.8008 13.5996H2.00078C1.77988 13.5996 1.60078 13.4205 1.60078 13.1996V4.39961C1.60078 4.17868 1.77988 3.99961 2.00078 3.99961H10.5002C10.5798 3.99961 10.6561 3.96801 10.7123 3.91173L10.9123 3.71173C11.1013 3.52273 10.9675 3.19961 10.7002 3.19961H2.00078C1.33803 3.19961 0.800781 3.73686 0.800781 4.39961V13.1996C0.800781 13.8624 1.33803 14.3996 2.00078 14.3996H10.8008C11.4635 14.3996 12.0008 13.8624 12.0008 13.1996V7.29163C12.0008 7.02436 11.6776 6.89051 11.4887 7.07948Z" fill="#0069F3"/>
                      </svg>
                       </a>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-3" >
                  <v-expansion-panel-header>
                      Solution Facilitator
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider horizontal class="mb-2"> </v-divider>
                      <div class="col-12 font-weight-bold mt-2 pb-0"> 
                        Name: 
                      </div>
                      <div class="col-12 text-gray"> 
                        {{ solution.name ? solution.name : '/' }}
                      </div>
                      <div class="col-12 mt-0 font-weight-bold">
                        <a @click="openWebsite()"> Visit Website
                           <svg  class="ml-1 pt-1" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.68866 10.9703C5.57151 10.8532 5.57151 10.6632 5.68866 10.546L13.7674 2.46728L13.7497 2.44961L11.5008 2.45816C11.3351 2.45816 11.2008 2.32383 11.2008 2.15816V1.90818C11.2008 1.74248 11.3351 1.60818 11.5008 1.60818L14.9008 1.59961C15.0665 1.59961 15.2008 1.73391 15.2008 1.89961L15.1922 5.29961C15.1922 5.46528 15.0579 5.59961 14.8922 5.59961H14.6422C14.4766 5.59961 14.3422 5.46528 14.3422 5.29961L14.3508 3.05066L14.3331 3.03298L6.25436 11.1117C6.13721 11.2289 5.94726 11.2289 5.83008 11.1117L5.68866 10.9703ZM11.4887 7.07948L11.2887 7.27948C11.2324 7.33574 11.2008 7.41205 11.2008 7.49161V13.1996C11.2008 13.4205 11.0217 13.5996 10.8008 13.5996H2.00078C1.77988 13.5996 1.60078 13.4205 1.60078 13.1996V4.39961C1.60078 4.17868 1.77988 3.99961 2.00078 3.99961H10.5002C10.5798 3.99961 10.6561 3.96801 10.7123 3.91173L10.9123 3.71173C11.1013 3.52273 10.9675 3.19961 10.7002 3.19961H2.00078C1.33803 3.19961 0.800781 3.73686 0.800781 4.39961V13.1996C0.800781 13.8624 1.33803 14.3996 2.00078 14.3996H10.8008C11.4635 14.3996 12.0008 13.8624 12.0008 13.1996V7.29163C12.0008 7.02436 11.6776 6.89051 11.4887 7.07948Z" fill="#0069F3"/>
                          </svg>
                           </a>
                      </div> 
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                        Point Of Contact: 
                      </div>
                      <div class="col-12 text-gray mt-0" v-if="solution.facilitator && solution.facilitator.primaryContact"> 
                        {{ solution.facilitator.primaryContact ? solution.facilitator.primaryContact.firstName : '/' }}
                        {{ solution.facilitator.primaryContact ? solution.facilitator.primaryContact.lastName : '/' }}
                      </div>   
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                        Email: 
                      </div>
                      <div class="col-12 text-gray mt-0" v-if="solution.facilitator && solution.facilitator.primaryContact"> 
                        {{ solution.facilitator.primaryContact ? solution.facilitator.primaryContact.email : '/' }}
                      </div>    
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                        Address: 
                      </div>
                      <div class="col-12 text-gray mt-0" v-if="solution.facilitator"> 
                        {{ solution.facilitator.address1 ? solution.facilitator.address1 : '/' }}
                      </div>                
                      </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="mb-3" >
                      <v-expansion-panel-header>
                          Enrollment/Participation
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-if="solution.contracted == true">
                          <div class="col-12 font-weight-bold mt-0 pb-0"> 
                            Provider participation:
                          </div>
                          <div class="col-12 text-gray mt-0"> 
                            {{ solution.participation.date | moment.utc('YYYY/MM/DD') }} 
                          </div>    
                          <div class="col-12 font-weight-bold mt-0 pb-0"> 
                            Solution Provider Participation:
                          </div>
                          <div class="col-12 text-gray mt-0"> 
                            
                          </div>                          
                         </v-expansion-panel-content>
                         <v-expansion-panel-content v-else>
                          <div class="col-12 font-weight-bold mt-0 pb-0"> 
                            Provider participation:
                          </div>
                          <div class="col-12 text-gray mt-0"> 
                            {{ solution.participation ? solution.participation.provider : '/' }} from Solution Facilitator
                          </div>    
                          <div class="col-12 font-weight-bold mt-0 pb-0"> 
                            Employer participation:
                          </div>
                          <div class="col-12 text-gray mt-0"> 
                            {{ solution.participation ? solution.participation.employer : '/' }} from Solution Facilitator
                          </div>                          
                         </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel class="mb-3" v-if="solution.contracted == true">
                          <v-expansion-panel-header>
                             Participation Details
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Contract PDF:
                              </div>
                              <div class="col-12 text-gray mt-0"> 
                                <a class="" @click="downloadFile" >Download file <svg width="19" height="19" class="" viewBox="1 1 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.9125 8.19844L8.21245 11.9109C8.09495 12.0284 7.90495 12.0284 7.78745 11.9109L4.08745 8.19844C3.96995 8.08094 3.96995 7.89094 4.08745 7.77344L4.26495 7.59594C4.38245 7.47844 4.57245 7.47844 4.68995 7.59594L7.57495 10.4959V2.69844C7.57495 2.53344 7.70995 2.39844 7.87495 2.39844H8.12495C8.28995 2.39844 8.42495 2.53344 8.42495 2.69844V10.4959L11.31 7.59844C11.4275 7.48094 11.6175 7.48094 11.735 7.59844L11.9125 7.77594C12.03 7.89094 12.03 8.08094 11.9125 8.19844ZM12.8 13.2984V13.0984C12.8 12.9334 12.665 12.7984 12.5 12.7984H3.49995C3.33495 12.7984 3.19995 12.9334 3.19995 13.0984V13.2984C3.19995 13.4634 3.33495 13.5984 3.49995 13.5984H12.5C12.665 13.5984 12.8 13.4634 12.8 13.2984Z" fill="#0069F3"/>
                                  </svg>
                                  </a>
                              </div>    
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Status: 
                              </div>
                              <div class="col-12 text-gray mt-0"> 
                                <v-chip
                                v-if="solution.participation.status == 'Active'"
                                color="#04D900"
                                text-color="white"
                              >
                               Active
                              </v-chip>
                              <v-chip
                              v-else-if="solution.participation.status == 'Pending Approval'"
                                color="#E6E6E6"
                                text-color="#999999"
                              >
                               Pending Approval
                              </v-chip>
                              <v-chip
                              v-else
                                color="#D90101"
                                text-color="white"
                              >
                              Inactive
                              </v-chip>
                              </div>  
                              <div class="col-12 blue--text font-18 mt-0 pb-0"> 
                                Rates for Vendor Services
                              </div> 
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Structure: 
                              </div>
                              <div class="col-12 text-gray mt-0"> 
                                {{ solution.rate ? solution.rate.structure : '/' }}
                              </div>   
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Frequency: 
                              </div>
                              <div class="col-12 text-gray mt-0"> 
                                Monthly Payment
                              </div>  <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Rate Chosen:
                              </div>
                              <div class="col-12 text-gray mt-0"> 
                                {{ solution.rate ? solution.rate.rateGroup : '/' }} <strong>US${{ solution.rate ? solution.rate.amount : '/' }}</strong>  PMPM
                              </div> 
                              <div class="col-12 blue--text font-18 mt-0 pb-0"> 
                                Total Solution Fee
                              </div>
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                TPA Fee:
                              </div>
                              <div class="col-12 text-gray mt-0" > 
                                Determined by the TPA on an employer basis. TPA fee may range from 0 to 2% per subscription per member per month (PMPM)
                              </div>  
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Apaly Fee:
                              </div>
                              <div class="col-12 text-gray mt-0" > 
                                3% per transaction 
                              </div>  
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Solution Facilitator Fee:
                              </div>
                              <div class="col-12 text-gray mt-0" > 
                                $5 dollars per transaction 
                              </div>  
                              <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                Total net PMPM (after fees):
                              </div>
                              <div class="col-12 text-gray mt-0" > 
                               US${{solution.rate?.totalNet}} PMPM
                              </div>  
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel class="mb-3" v-else>
                            <v-expansion-panel-header>
                                Rates & Fees
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div class="col-12 blue--text font-18 mt-0 pb-0"> 
                                  Rates for Vendor Services
                                 
                                </div>
                                <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                  Structure:: 
                                </div>
                                <div class="col-12 text-gray mt-0"> 
                                  {{ solution.rate ? solution.rate.structure : '/' }}
                                </div>    
                                <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                  Frequency: 
                                </div>
                                <div class="col-12 text-gray mt-0"> 
                                  {{ solution.rate ? solution.rate.frequency : '/' }}
                                </div>   
                                <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                  Amount: 
                                </div>
                                <div class="col-12 text-gray mt-0"> 
                                  {{ solution.rate ? solution.rate.amount : '/' }}
                                </div>   
                                <br />
                                <div class="col-12 blue--text font-18 mt-0 pb-0"> 
                                  Total Solution Fee
                                </div>
                                <div v-for="(item,index) in solution.fees" :key="index"> 
                                    <div class="col-12 font-weight-bold mt-0 pb-0"> 
                                  {{item.entity.charAt(0).toUpperCase() + item.entity.slice(1)}} Fee:
                                </div>
                                <div class="col-12 text-gray mt-0" v-if="item.type == 'percent'"> 
                                  {{ item.amount }}%
                                </div>    
                                <div class="col-12 text-gray mt-0" v-else> 
                                  {{ item.amount }}
                                </div>
                               </div>
                               
                                </v-expansion-panel-content>
                            </v-expansion-panel>
            </v-expansion-panels>
        </div>
      </div>
    </div>
    <v-dialog
    hide-overlay
    v-model.trim="errorModal"
    height="320"
    width="400"
    style="margin-top: 30vh"
    content-class="modal-member-check" >
    <v-card>
      <v-card-text>
        <div class="col-md-12 pt-10 text-center" @click="errorModal = false">
          <div class="red-error-modal">
            <img src="/svg/error-modal-icon.svg" />
            <div class="error-modal-red-text">Please select rate first</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
    <v-dialog v-model.trim="participation" width="1000"  persistent>
        <v-card class="col-md-12 pa-0" >
            <div class="col-md-12 text-end pb-0"> 
                <h3 @click="closeDialog()">X</h3>
              </div>
          <v-card-title class="text-h5 pt-0 pb-0"> Enrollment </v-card-title>
            <v-stepper v-model="step">
              <v-stepper-header  class="col-7 ma-auto pt-0 pb-0">
                <v-stepper-step
                  :complete="step > 1"
                  step="1"
                >
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="step > 2"
                  step="2"
                >
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    class="mb-12"
                  >
                <h4 class="font-weight-bold"> Choose your subscription rate</h4>
                <div class="col-12 d-md-flex"> 
                 <div class="col-12 col-md-6 col-lg-6 col-xl-6">  
                  <div class="col-12 pl-0 pb-0"> 
                  <v-select
                  class="pb-0"
                 solo
                 return-object
                 requried
                 v-model="solutionRate"
                 :items="rates"
                 item-text="group"
                 item-value="_id"
                 placeholder="Select one of your rate groups*"
                 @change="selected()"
               >
               <template #item="{ item }">
                <span class="d-flex col-12 pa-0"><p class="col-6">{{ item.group }} </p> <p class=" col-4 text-end ml-10 font-weight-bold">US${{ item.baseRate }}</p>  </span>
              </template>
            </v-select>
             </div>
             <div class="pt-0">  
               <p class="text-gray font-12 pt-0">*You may adjust or create a new rate  under the
                 <a class="text-underline" @click="$router.push('/dpc-providers/rates')">Rate Tab</a> </p>
             </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">  
              <!-- <div class="pt-0" v-if="errorText">  
                <v-alert
                border="bottom"
                colored-border
                color="error"
                elevation="2"
                class="text-gray"> You need to choose a rate first!</v-alert>
              </div> -->
              <div height="350px" class=" mb-5 mt-2 rate-shadow " v-if="solutionRate">
              <div class=" pl-1 pr-1 pb-4 display">
                <span class="mt-2 font-weight-bold ml-3">
                  Monthly Amount
              </span>
              <div class="col-12 d-flex pa-0"> 
                <div class="col-9 pb-0 text-start text-gray">
                  DPC Base Rate
                </div>
                <div class="col-3 pb-0 text-end font-weight-bold"> US${{ rateInformation.monthly.baseRate.toFixed(2) }}</div>
              </div>
              <div class="col-12 d-flex pa-0"> 
                <div class="col-9 text-start pb-0 pt-0 text-gray">
                  Total Processing Fee
                </div>
                <div class="col-3 pb-0 pt-0 text-end font-weight-bold"> US${{ rateInformation.monthly.fees.toFixed(2) }}</div>
              </div>
              <v-divider horizontal></v-divider>
              <div class="col-12 d-flex pl-0 pr-0"> 
                <div class="col-9 text-start pb-0 pt-0 text-gray">
                  Total net PMPM received after fees
                </div>
                <div class="col-3 pb-0 pt-0 text-end font-weight-bold"> US${{ rateInformation.monthly.total.toFixed(2) }}</div>
              </div>
              <span class="mt-2 font-weight-bold ml-3">
                Annual Amount
            </span>
            <div class="col-12 d-flex pa-0"> 
              <div class="col-9 pb-0 text-start text-gray">
                DPC Base Rate
              </div>
              <div class="col-3 pb-0 text-end font-weight-bold"> US${{ rateInformation.yearly.baseRate.toFixed(2) }}</div>
            </div>
            <div class="col-12 d-flex pa-0"> 
              <div class="col-9 text-start pb-0 pt-0 text-gray">
                Total Processing Fee
              </div>
              <div class="col-3 pb-0 pt-0 text-end font-weight-bold"> US${{ rateInformation.yearly.fees.toFixed(2) }}</div>
            </div>
            <v-divider horizontal></v-divider>
            <div class="col-12 d-flex pl-0 pr-0"> 
              <div class="col-9 text-start pb-0 pt-0 text-gray">
                Total net PMPM received after fees
              </div>
              <div class="col-3 pb-0 pt-0 text-end font-weight-bold"> US${{ rateInformation.yearly.total.toFixed(2) }}</div>
            </div>
              </div>
            </div>
            </div>
                </div>
                </v-card>
               <div align="end">
                <v-btn  color="gray" class="mr-3" width="150px"  @click="closeDialog()">
                  Back
                </v-btn> 
                <v-btn :disabled="!solutionRate" width="150px" color="primary" @click="step2()">
                    Next
                  </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card  class="mb-12 " height="60vh">
                    <h4 class="font-weight-bold">          
                  Solution Facilitator Contract Agreement 
                </h4> 
                   <div class="" v-if="solution.contract">
                    <iframe
                    :src="pdfUrl"
                    :style="{
                      height: $vuetify.breakpoint.xsOnly ? '300px' : '50vh',
                      width: $vuetify.breakpoint.xsOnly ? '100%' : '100%',
                    }"
                  ></iframe>
                </div>
                </v-card>
                <!-- <div class="pt-0" v-if="checkTerms">  
                  <v-alert
                  border="bottom"
                  colored-border
                  color="error"
                  elevation="2"
                  class="text-gray"> You need to accept Terms and Agreement first!</v-alert>
                </div> -->
                <div  class="d-flex col-12">
                  <div class="col-6">
                     <v-checkbox
                     v-model="agreeWithTerms"
                  label="I agree with the terms">
                  </v-checkbox>
                  </div>
                  <div class="col-6" align=end>
                     <v-btn color="gray" class="mr-3"  width="150px" @click="step = 1">
                      Back
                    </v-btn>
                    <v-btn :disabled="!agreeWithTerms" color="primary"  width="150px" @click="accept()">
                     Participate
                   </v-btn> 
                  </div>
                </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
        </v-card>
      </v-dialog>
    <div class="gray-top-border">
      <div class="col-md-12" align="end">        <span
        class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
        @click="$router.go(-1)"
        >Close</span
      >
    <v-dialog v-model.trim="agreeParticipate" width="600" class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="display mt-3">
              <span class="text-center mt-2 font-weight-bold success-text mt-5 mb-3">
              <h2> Success! You have enrolled in this solution.</h2> 
          </span>
          <span class="text-center font-18 mt-2 font-weight-bold color-black mt-5 mb-3">
            Your approval is pending and you will be notified once the solution facilitator has reviewed and approved your participation 
      </span>
      <span class="text-center font-weight-bold success-text mt-5 mb-2">
            <v-btn color="primary"  width="150px" @click="gotIt()">
                        Got It!
                 </v-btn> 
    </span>
      
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
      </div>
    </div>
    <!-- <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal> -->
    <!-- <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal> -->
  </div>
</template>
  
  <script>
// import successModal from "../../../TablesTemplate/SuccessDialog.vue";
// import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../services/api";
import { saveAs } from 'file-saver';
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      panel: [0],
      disablePointer: true,
      contractLink: "",
      checkTerms: false,
      agreeWithTerms: false,
      pdfUrl: "",
      agreeParticipate: false,
      rateInformation: {},
      errorText: false,
      solutionRate: "",
      solution: {},
      step: 1,
      // logo: "",
      // solution: {},
      enrollment: true,
      contract: false,
      success: false,
      participation: false,
      // item: {
      //   category: "DPC",
      //   url: "",
      //   contact: "",
      //   email: "",
      //   definition: "",
      //   description: "",
      // },
      errorModal: false,
      rates: [],
      rate: null,
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },

  created() {
    this.getSolution();
    this.getRates();
  },
  methods: {
    downloadFile() {
      const fileUrl = this.contractLink;
      const fileName = 'contract.pdf';
          saveAs(fileUrl, fileName);
     
        
    },
    openUrl(){
        window.open(`${this.solution.details.website}`);
      },
      openWebsite(){
        window.open(`${this.solution.website}`);
      },
    gotIt() {
      this.agreeParticipate = false;
      this.$router.push("/dpc-providers/solutions");
    },
    accept() {
      if (this.agreeWithTerms == true) {
        let data = {
          rate: this.solutionRate._id,
          contracted: true,
        };
        api()
          .post(`/dpcproviders/solutions/${this.solution.id}`, data)
          .catch((err) => {
          if (err) {
            this.participation = false;
            this.step = 1;
             this.solutionRate = "";
            this.rateInformation = {};
            this.agreeWithTerms = false;
            this.$refs.error_modal.openPopUp(err.response);
     
          }
        })
          .then((res) => {
            if (res) {
              console.log(res.data);
              this.participation = false;
              this.agreeParticipate = true;
              this.step = 1;
              this.solutionRate = "";
              this.rateInformation = {};
              this.agreeWithTerms = false;
            
            }
          });
      
       
      } else {
        this.checkTerms = true;
      }
    },
    closeErrorModal() {
      // this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      // this.$refs.success_modal.closePopUp();
      // window.location.reload();
    },
    getSolution() {
      api()
        .get("/dpcproviders/market/solution/" + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.solution = res.data;
            this.pdfUrl = res.data.contract;
            this.contractLink = res.data.contract;
          }
        });
    },
    getRates() {
      api()
        .get("/dpcproviders/rates")
        .then((res) => {
          if (res) {
            this.rates = res.data;
          }
        });
    },
    leaveSolution(item) {},
    participateSolution(item) {
      this.participation = true;
    },
    selected() {
      this.errorText = false;
      let data = {
        solution: this.solution.id,
        rate: this.solutionRate.baseRate,
      }
      api()
        .post("/dpcproviders/rate/calculate", data)
        .then((res) => {
          if (res) {
            this.rateInformation = res.data;
          }
        });
    },
    participationDialog() {
      this.participation = true;
    },
    closeDialog() {
      this.errorText = false;
      this.solutionRate = "";
      this.participation = false;
    },
    step2() {
      if (this.solutionRate == "") {
        this.errorText = true;
      } else {
        this.step = 2;
      }
    },
    participate() {
      if (this.rate == null) {
        this.errorModal = true;
      }
      let data = {
        solutionId: this.$route.params.id,
        rate: this.rate._id,
        contracted: true,
      };
      api()
        .post("/dpcproviders/solutions", data)
        .then((res) => {
          if (res) {
            this.$router.push(`dpc-providers/solutions`);
          }
        });
    },
    favorite() {
      let data = {
        entity: 'solution',
        solution: this.$route.params.id,
        favorite: !this.solution.favorite,
      };
      api()
        .post("/dpcproviders/market/favorite", data)
        .then((res) => {
          if (res) {
            this.solution.favorite = !this.solution.favorite;
          }
        });
    },
 
  },
};
</script>
  <style scoped>
.success-text {
  color: #014d00;
}
.text--white{
  color: white !important;
}
.participate {
  width: 150px;
  color: white;
}
.next {
  width: 650px;
  color: white;
}
.okay {
  width: 250px;
  color: white;
}
.status-size{
  width: 270px !important;
  border-radius: 8px;
}
.no-pointer {
  cursor: default;
}
.v-stepper__header {
  box-shadow: none !important;
}
.v-sheet .theme--light {
  box-shadow: none !important;
}
.rate-shadow {
  box-shadow: 0px 1px 12px 3px rgb(166, 161, 161);
}
.frame-style {
  border: none !important;
}
.pdf {
  width: 100%; /* Adjust the width as needed */
  height: 600px; /* Adjust the height as needed */
}
</style>
  