<template>
  <div>

  </div>

</template>
<script>
import api from "@/services/api";
export default {
props:{
  newMemberPlanModalStatus:{
        required:true
    }
},
  data() {
    return {
        valid:false,

    };
  },
  methods: {

    goBack(){
      this.$emit('closeMemberNewPlanModal')
    },
    pullBenefits(){
      this.$refs.form.validate()
      if(this.valid){
        //pull benefits
      }

    }
  },
  created() {
    console.log('init')
  },
};
</script>