<template>
  <div class="d-flex justify-space-between align-center mobile-padding" :style="'color: '+fontColor">
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$store.commit('openMobileMenu',true)" style="z-index:10">
      <path d="M1 15H23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 8H23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 1H23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8365 21.8468C15.9998 23.3604 13.9057 23.3197 12.6708 22.1003L9.89643 19.3605C9.50694 18.9758 8.88227 18.9993 8.37891 19.2144C7.41726 19.6253 6.25792 19.4427 5.47199 18.6666C4.69352 17.8978 4.50471 16.7672 4.90555 15.8229C5.12327 15.3101 5.14794 14.6711 4.75149 14.2796L1.91101 11.4745C0.676206 10.2551 0.634981 8.18694 2.16808 7.36123C3.79799 6.48338 5.03573 6.54658 5.79936 6.58557C6.06696 6.59923 6.27633 6.60992 6.42396 6.57611C7.18685 6.40134 7.86918 6.06143 8.42848 5.57751L11.4739 2.57008C12.5274 1.47901 14.0575 0.921495 15.7586 1.00892C17.4596 1.09635 19.2065 1.82228 20.6507 3.04173C24.2609 6.26979 23.2539 11.1149 20.6933 13.6436L18.6448 15.6666C18.1548 16.2189 17.8106 16.8927 17.6336 17.6461C17.5994 17.7918 17.6101 17.9983 17.6238 18.2622C17.6628 19.0159 17.7261 20.2374 16.8365 21.8468Z" stroke="white" stroke-width="2"/>
      <circle cx="19" cy="15" r="4" fill="#C24141" stroke="white" stroke-width="2"/>
    </svg>
  </div>
  
</template>
<script>
export default {
  props:['fontColor'],
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
