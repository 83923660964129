<template>
  <div class="register-page pt-1">
    <div class="register-page-header text-right">
      Are you a Provider or TPA?
      <a href="https://apaly.net" target="blank"
        ><span class="link-text">Access here</span></a
      >
    </div>
    <div class="register-page-container">
      <!-- <div class="register-page-logo">
        <img class="apaly-logo" src="/apaly-logo.png" alt="">
      </div> -->
      <div class="register-page-form mt-5">
        <v-form
          style="width: 100%"
          v-model.trim="valid"
          ref="form"
          class="authentication-page-right-side-form"
        >
          <p class="font-size-32 mb-0" style="font-size: 24px">
            Let’s get you signed up
          </p>
          <p class="mb-4">First, we need to confirm your email</p>
          <div>
            <label class="label-color"
              >First Name
              <small class="small-color"
                >(as on your Insurance Card)
              </small></label
            >
            <v-text-field
              placeholder="First Name"
              outlined
              dense
              v-model.trim="user.firstName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div>
            <label class="label-color"
              >Last Name
              <small class="small-color"
                >(as on your Insurance Card)
              </small></label
            >
            <v-text-field
              placeholder="Last Name"
              outlined
              dense
              v-model.trim="user.lastName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div style="position: relative">
            <label class="label-color">Company Email</label>
            <v-text-field
              placeholder="email@domain"
              :class="emailErrorMessage ? 'mb-0' : 'mb-4'"
              outlined
              dense
              @blur="checkEmail()"
              hide-details
              v-model.trim="user.email"
              :rules="emailRules"
            ></v-text-field>
            <!-- <div class="mb-2 " style="position:absolute;bottom:0">
              <span style="color:#ff5252;font-size:12px"  v-if="emailErrorMessage">{{emailErrorMessage}}</span>
            </div> -->
            <p
              class="input-error-message mt-0 mb-0 pa-0"
              v-if="
                emailErrorMessage &&
                emailErrorMessage.reason == 'email' &&
                emailErrorMessage.message ==
                  'This e-mail is already registered.'
              "
              style="color: red; font-size: 10pt"
            >
              {{ emailErrorMessage.message }} Use another one or
              <a @click="$router.push({ name: 'MemberLogin' })">login</a>
            </p>
            <p
              class="input-error-message mt-0 mb-0 pa-0"
              v-else-if="
                emailErrorMessage && emailErrorMessage.reason == 'email'
              "
              style="color: red; font-size: 10pt"
            >
              {{ emailErrorMessage.message }}
            </p>
            <p
              class="input-error-message mt-0 mb-0 pa-0"
              v-else-if="
                emailErrorMessage && emailErrorMessage.reason == 'company'
              "
              style="color: red; font-size: 10pt"
            >
              {{ emailErrorMessage.message }}
            </p>
            <p
              class="input-error-message mt-0 mb-0 pa-0"
              v-else-if="emailErrorMessage"
              style="color: red; font-size: 10pt"
            >
              {{ emailErrorMessage }}
            </p>
          </div>
          <div>
            <label class="label-color">Password</label>
            <v-text-field
              placeholder="*********"
              outlined
              dense
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              v-model.trim="user.password"
              :rules="passRules"
            ></v-text-field>
          </div>
          <div>
            <label class="label-color">Confirm Password</label>
            <v-text-field
              placeholder="*********"
              @keyup="checkPassword()"
              outlined
              dense
              :type="showPasswordConfirmation ? 'text' : 'password'"
              @click:append="
                showPasswordConfirmation = !showPasswordConfirmation
              "
              :append-icon="
                showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
              "
              v-model.trim="user.confirmPassword"
              :rules="confirmPassRules"
            ></v-text-field>
            <!-- <p class="text-under-input font-size-12" v-if="!passwordMatches">Password and Confirm Password does not match</p> -->
          </div>
          <div>
            <v-checkbox
              v-model="user.terms_and_conditions"
              :rules="requiredRules"
              class="mt-0 pt-0"
            >
              <div class="font-12 black-text mt-0 pt-0" slot="label">
                I accept the terms of
                <a class="text-underline" @click="openPdfModal()"
                  >Apaly Health Terms and Conditions
                </a>
              </div></v-checkbox
            >
          </div>
          <div class="form-button mt-0 pt-0">
            <v-btn
              class="primary pt-0"
              :disabled="!valid || !emailStatus"
              @click="register()"
              >Register</v-btn
            >
          </div>
        </v-form>
      </div>
      <v-col
        v-if="error.length > 0"
        class="register-page-error-box mt-3 mb-3 col-md-12"
      >
        <v-alert dense outlined type="error">
          {{ error }}
        </v-alert>
      </v-col>
      <div class="register-page-footer">
        Already have an account
        <span class="link-text" @click="$router.push({ name: 'MemberLogin' })">
          Login
        </span>
      </div>
    </div>
    <v-dialog v-model.trim="checkEmailDialog" width="510" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p
              class="font-weight-bold"
              @click="$router.push({ name: 'MemberLogin' })"
            >
              X
            </p>
          </span>
          <div class="display">
            <div class="text-center">
              <img src="/svg/email_sent.svg" />
            </div>
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Verify your E-mail Address
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              We've sent a confirmation link to {{ this.user.email }}
            </div>
          </div>

          <div class="text-center text--white mt-6">
            <v-btn
              color="primary"
              class="email-sent"
              @click="$router.push({ name: 'MemberLogin' })"
            >
              Back To Login
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="pdfDialog">
      <v-card>
        <span role="button" align="end" class="pt-0 pr-0">
          <h1 class="font-weight-bold mr-4" @click="pdfDialog = false">X</h1>
        </span>
        <v-card-text class="success-modal pt-5">
          <object
            :data="pdfUrl"
            type="application/pdf"
            width="100%"
            height="1000"
          ></object>
        </v-card-text>
      </v-card>
    </v-dialog>
    <errorModal
      v-if="somethingWrong"
      :someThingWentWrong="somethingWrong"
      ref="error_modal"
    ></errorModal>
  </div>
</template>

<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import errorModal from "../../../Common/SomethingWentWrong.vue";

export default {
  components: {
    errorModal,
  },
  data() {
    return {
      somethingWrong: false,
      selectedProvider: null,
      checkEmailDialog: false,
      user: {},
      showPassword: false,
      showPasswordConfirmation: false,
      valid: false,
      emailErrorMessage: null,
      emailStatus: false,
      pdfUrl: "",
      pdfDialog: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital letter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital letter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        (v) => v == this.user.password || "Password must match",
      ],
      passwordMatches: true,
      error: "",
      registerSemafor: true,
    };
  },
  methods: {
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    checkEmail() {
      this.user.email = this.user.email.toLowerCase();

      const isEmailValid = this.emailRules.every(
        (rule) => rule(this.user.email) === true
      );
      if (!isEmailValid) {
        this.emailErrorMessage = "E-mail must be valid";
        return;
      }
      api()
        .post(`/member/app/validate/email`, { email: this.user.email })
        .then((res) => {
          if (res.data.valid) {
            this.emailStatus = true;
            this.emailErrorMessage = null;
          } else {
            this.emailStatus = false;
            this.emailErrorMessage = res.data;
          }
        })
        .catch((err) => {
          if (err.status == 403) {
            this.emailErrorMessage = err.response.data;
            this.emailStatus = false;
          } else if (err.status == 400) {
            this.emailErrorMessage = err.response.data;
            this.emailStatus = false;
          }
        });
    },
    checkPassword() {
      if (this.user.password !== this.user.confirmPassword) {
        this.passwordMatches = false;
      } else {
        this.passwordMatches = true;
      }
    },
    register() {
      let companyFilter = this.$route.query.company;
      let locationFilter = this.$route.query.location;
      let query = `${companyFilter || locationFilter ? "?" : ""}`;
      if (companyFilter) query += `company=${companyFilter}`;
      if (locationFilter)
        query += `${companyFilter ? "&" : ""}location=${locationFilter}`;

      // Simplified by T.N.
      // let query = ''
      // if(this.$route.query.company && this.$route.query.company != undefined ){
      //    query = '?company=' + this.$route.query.company;
      // }
      // else {
      //   query = ''
      // }

      this.$refs.form.validate();
      if (this.valid && this.emailStatus) {
        if (this.selectedProvider) {
          this.user.providerId = this.selectedProvider.id;
          this.user.locationId = this.selectedProvider.locationId;
        }

        if (!this.registerSemafor) return; // example of using semafore for sensitive calls like registration
        this.registerSemafor = false;
        api()
          .post(`/member/app/signup` + query, this.user)
          .then((res) => {
            if (res) {
              this.checkEmailDialog = true;
              // this.login()
              this.registerSemafor = true;
            }
          })
          .catch((err) => {
            // this.$swal(err.response.data,'','error');
            this.somethingWrong = true;
            this.registerSemafor = true;
          });
      }
    },
    openPdfModal() {
      const url =
        "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditionsMember.pdf";
      this.pdfUrl = url;
      this.pdfDialog = true;
    },
    // login(){
    //   if (this.valid){
    //     if (localStorage.getItem("user")) {
    //     localStorage.removeItem("user");
    //   }
    //     if (this.$cookies.get("token")) {
    //       this.$cookies.remove("token");
    //     }
    //     let data = {
    //       "email": this.user.email,
    //       "password": this.user.password,
    //     }
    //     api().post(`/member/app/login`, data).then((res)=>{
    //       if(res){
    //         res.data.type = 'dpc-member';
    //         this.$cookies.set("token", res.data.token);
    //         localStorage.setItem("user", JSON.stringify(res.data));
    //         this.getPlans()
    //         // if (res.data.eligibility.edi271 == undefined){
    //         //   this.$router.push({name:'MemberPersonalInfo'})
    //         // }else{
    //         //   this.$router.push({name:'WelcomePage'})
    //         // }
    //       }
    //     }).catch((err)=>{
    //         this.$swal('error',err.response.data.message,'');
    //     });
    // }
    // },
    getPlans() {},
    closeMemberPlanModal() {
      this.membersPlanModalStatus = false;
    },
  },
  created() {
    // localStorage.clear();
    this.$cookies.remove("token");
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: 'Member Register', // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
    this.selectedProvider = this.$store.getters.getSelectedProvider;
  },
};
</script>
<style scoped lang="scss">
.label-color {
  color: #333333 !important;
  font-weight: 400;
}
.small-color {
  color: gray !important;
}
.register-page {
  padding: 64px;
}
.apaly-logo {
  width: 130px;
}
.register-page-container {
  width: 60%;
  margin: 0 auto;
}
.register-page-logo {
  text-align: center;
  position: absolute;
  left: 30px;
}
.register-page-title {
  margin-top: 20px;
  text-align: center;
}
.register-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}
.register-page-error-box {
  padding: 20px 140px;
}
.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.authentication-page-right-side-form {
  align-self: center;
  padding: 0 140px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .router-view {
    padding-top: 0px !important;
  }
  .register-page-logo {
    position: inherit;
  }
  .register-page {
    padding: 24px;
  }
  .register-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .register-page-container {
    width: 100%;
  }
  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .register-page-error-box {
    padding: 10px 0;
  }
  .authentication-page-right-side-form {
    padding: 0;
  }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
