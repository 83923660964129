<template>
  <div class="sideMenu d-flex flex-column" style="box-shadow: 0 10px 10px #CCCC" :style="$store.getters.getMobileMenuState ? 'left:0;' : 'left:0px;'">
    <div class="info-box-list mt-0" @click="navigateTo('/home')" >
      <div class="d-flex info-box-list-item" role="button">
        <svg width="16" height="16" viewBox="0 0 17 18" class="mt-1"  fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
          stroke="#707070" stroke-width="1.5"  stroke-linecap="round"   stroke-linejoin="round"
        />
      </svg>
        <div class="font-16 font-weight-400">Home</div>
      </div>
    </div>
   <div class="info-box-list" @click="navigateTo('/marketplace')" >
      <div class="d-flex info-box-list-item" role="button">
        <svg width="18" height="18" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.8382 7.00365L20.4107 1.00365C20.1969 0.632402 19.7994 0.399902 19.3682 0.399902H4.63064C4.19939 0.399902 3.80189 0.632402 3.58814 1.00365L0.160638 7.00365C-0.296863 7.80615 0.276888 8.7999 1.19939 8.7999H2.39939V18.3437C2.39939 19.0374 2.93564 19.5999 3.59939 19.5999H13.1994C13.8632 19.5999 14.3994 19.0374 14.3994 18.3437V8.7999H20.3994V19.2999C20.3994 19.4649 20.5344 19.5999 20.6994 19.5999H21.2994C21.4644 19.5999 21.5994 19.4649 21.5994 19.2999V8.7999H22.7994C23.7219 8.7999 24.2957 7.80615 23.8382 7.00365ZM13.1994 18.3437C13.1994 18.3774 13.1882 18.3962 13.1919 18.3999L3.62189 18.4074C3.62189 18.4074 3.59939 18.3887 3.59939 18.3437V13.5999H13.1994V18.3437ZM13.1994 12.3999H3.59939V8.7999H13.1994V12.3999ZM1.20314 7.5999L4.63064 1.5999H19.3682L22.7994 7.5999H1.20314Z" fill="#333333"/>
          </svg>
        <div class="font-16 font-weight-400">Marketplace</div>
      </div>
    </div>
   <div class="info-box-list" @click="navigateTo('/account')" >
      <div class="d-flex info-box-list-item" role="button">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.3616 11.1999C11.2853 11.1999 10.7678 11.7999 9.00156 11.7999C7.23531 11.7999 6.72156 11.1999 5.64156 11.1999C2.85906 11.1999 0.601562 13.4574 0.601562 16.2399V17.7999C0.601562 18.7937 1.40781 19.5999 2.40156 19.5999H15.6016C16.5953 19.5999 17.4016 18.7937 17.4016 17.7999V16.2399C17.4016 13.4574 15.1441 11.1999 12.3616 11.1999ZM16.2016 17.7999C16.2016 18.1299 15.9316 18.3999 15.6016 18.3999H2.40156C2.07156 18.3999 1.80156 18.1299 1.80156 17.7999V16.2399C1.80156 14.1212 3.52281 12.3999 5.64156 12.3999C6.37656 12.3999 7.10781 12.9999 9.00156 12.9999C10.8916 12.9999 11.6266 12.3999 12.3616 12.3999C14.4803 12.3999 16.2016 14.1212 16.2016 16.2399V17.7999ZM9.00156 9.9999C11.6528 9.9999 13.8016 7.85115 13.8016 5.1999C13.8016 2.54865 11.6528 0.399902 9.00156 0.399902C6.35031 0.399902 4.20156 2.54865 4.20156 5.1999C4.20156 7.85115 6.35031 9.9999 9.00156 9.9999ZM9.00156 1.5999C10.9853 1.5999 12.6016 3.21615 12.6016 5.1999C12.6016 7.18365 10.9853 8.7999 9.00156 8.7999C7.01781 8.7999 5.40156 7.18365 5.40156 5.1999C5.40156 3.21615 7.01781 1.5999 9.00156 1.5999Z" fill="#333333"/>
          </svg>
          
        <div class="font-16 font-weight-400">Account</div>
      </div>
    </div>
  

  </div>

</template>
<script>
export default {
  components: {},
  data() {
    return {
      drawer: null,
    };
  },
  created() {},
  computed: {},
  methods: {
    navigateTo(pathName){
      // Empty Dependent ID from Store
      this.$store.commit("setDependent", "");

      this.$store.commit('openMobileMenu',false)
      this.$router.push(pathName).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    logout() {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.sideMenu{
  position: fixed;
  left: -330px;
  z-index:2;
  width: 330px;
  height: 100vh;
  background: white;
  padding:20px;
  display: block !important;
}
.info-box-list{
  padding: 15px;
  margin: 5px 0;
  border-bottom: 1px solid #C9C9C9;
  text-decoration: none;
}
.info-box-list:last-child{
  border-bottom: none;
}

.info-box-list-item  > svg {
  margin-right: 10px;
}


.background-gray{
  background-color: #F2f2f2;
}

@media only screen and (max-width: 600px) {
    .sideMenu{
    //     position: fixed;
    //     left: -330px;
    //     z-index:2;
    //     width: 0px;
    //     height: 100vh;
    //     background: white;
    //     padding:20px;
        display: none !important;
    }
}
</style>
