<template>
  <div class="page-background">
    <main-header :title="'Add Family Member'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <v-form  v-model.trim="valid" ref="form">
        <div>
          <h3 class="from-headers">Personal Information</h3>
          <v-text-field dark outlined label="First Name" class="input-text" v-model="member.firstName" :rules="requiredRules"></v-text-field>
          <v-text-field dark outlined label="Last Name" v-model="member.lastName" :rules="requiredRules"></v-text-field>
          <v-text-field dark type="date" outlined label="Date of Birth" v-model="member.dob" :rules="requiredRules"></v-text-field>
          <v-select dark :items="genders" label="Gender" outlined v-model="member.gender" :rules="requiredRules"></v-select>
        </div>

        <div>
          <h3 class="from-headers">Contact</h3>
          <v-text-field dark type="email" outlined label="Email" v-model="member.email" :rules="emailRules"></v-text-field>
          <v-text-field dark type="tel" outlined label="Phone" placeholder="000-000-0000 " v-model="member.phone" :rules="requiredRules"></v-text-field>
          <v-text-field dark type="number" placeholder="00000" outlined label="Home Zip Code" v-model="member.zipCode" ></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">
            Insurance Information <br />
            <span class="from-sub-headers">(from your insurance card)</span>
          </h3>
          <v-text-field dark outlined label="Member ID" placeholder="000-000000" v-model="member.memberId" :rules="requiredRules" disabled></v-text-field>
          <v-text-field dark outlined label="Group ID" placeholder="000-000000" v-model="member.groupId" :rules="requiredRules" disabled></v-text-field>
          <v-text-field dark outlined label="Payer ID" placeholder="000-000000" v-model="member.payerId" :rules="requiredRules" disabled></v-text-field>
          <v-text-field dark outlined label="Plan ID" placeholder="000-000000" v-model="member.planId" :rules="requiredRules" disabled></v-text-field>

          <v-select dark :items="relationships" label="Select" v-model="member.subscriberRelation" outlined disabled></v-select>
        </div>
      </v-form>


    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn color="white" class="footer-btn" @click="submit">Next</v-btn>
      </div>
    </div>
    <v-snackbar
        v-model="snackbar"
        timeout="3000"
    >
      {{textInSnackbar}}
    </v-snackbar>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";
export default {
  data() {
    return {
      valid:false,
      snackbar:false,
      textInSnackbar:'',
      relationships: ["Spouse"],
      genders: [
        {
          text: "Male",
          value: "m",
        },
        {
          text: "Female",
          value: "f",
        },
      ],
      loggedMember: JSON.parse(localStorage.getItem("user")),
      member:{
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        email: "",
        phone: "",
        zipCode: "",
        memberId: '',
        groupId: '',
        planId: '',
        payerId: '',
        subscriberRelation:"Spouse"
      },
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  components: {
    mainHeader,
  },
  methods: {
    submit(){
      this.$refs.form.validate();
      if (this.valid){
        api()
            .post(`/member/app/family/member`, this.member)
            .then((res) => {
              if (res) {
                this.$router.push('/account/alert/add');
              }
            })
            .catch((err) => {
              this.snackbar = true;
              this.textInSnackbar=err.response.data;
            });
      }
    }

  },
  created() {
    this.member.memberId = this.loggedMember.memberId;
    this.member.groupId = this.loggedMember.groupId;
    this.member.planId = this.loggedMember.planId;
    this.member.payerId = this.loggedMember.payerId;
  },
};
</script>
<style scoped lang="scss">
.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background{
  background-color: #001e46;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}

// ::-webkit-scrollbar {
//   display: none;
// }


</style>
