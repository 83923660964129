<template>
    <div>
      <header-menu></header-menu>
      <div class="mobile-content-background">
        <div class="mobile-content">
          <v-select dark :items="members" :item-text="(i) =>i .firstName+' '+i.lastName" item-value="_id" v-model="member">
            <template v-slot:item="slotItem">
              <div class="d-flex justify-space-between info-box-list-select width-100">
                <div>
                  {{ slotItem.item.firstName +' '+ slotItem.item.lastName }}
                </div>
                <div>
                  {{ slotItem.item.provider ? slotItem.item.provider.companyName : 'Needs a provider' }}
                </div>
              </div>
            </template>
          </v-select>

          <v-btn elevation="1" outlined rounded x-large text class="width-100 white-background">
            <router-link :to="{name:'FindCareSearch'}" class="d-flex align-center">
              <svg class="mr-4" width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 9.25C17 16.5563 8.55556 23 8.55556 23C8.55556 23 1 16.5563 1 9.25C1 4.69365 4.58172 1 9 1C13.4183 1 17 4.69365 17 9.25Z" stroke="#0573F3" stroke-width="1.5" stroke-linejoin="round"/>
                <circle cx="9" cy="9.5" r="3.5" stroke="#0573F3" stroke-width="1.5"/>
              </svg>
              Find Care by Zip Code
            </router-link>
          </v-btn>

          <div class="d-flex flex-column info-box p-5 mt-5">
            <div class="d-flex justify-space-between info-box-list">
              <div>Provider</div>
              <div class="blue--text" v-if="member.provider">{{ member.provider.companyName }}</div>
              <div class="blue--text" v-else @click="$router.push({name:'FindCareSearch'})">Select Provider</div>
            </div>
            <div v-if="member.provider" class="d-flex justify-space-between info-box-list">
              <div>Next Visit</div>
              <div v-if="member.nextVisit">{{ member.nextVisit }}</div>
              <div class="blue--text" v-else>Schedule Visit</div>
            </div>
            <div v-if="member.provider" class="d-flex justify-space-between info-box-list">
              <div>Membership status</div>
              <div>Active</div>
            </div>
          </div>
          <div class="d-flex flex-column info-box p-5 mt-5">
            <div class="info-box-list-single text-center">
              <div>Rotating Informational Messages</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import headerMenu from "@/components/Sections/DPCMember/Layout/headerMenu";
import api from "@/services/api";
export default {
  data() {
    return {
      member:{},
      members:[],
    };
  },
  components:{
    headerMenu
  },
  methods: {},
  created() {
    api().get("/member/app/family/members")
        .then((res) => {
          this.members = res.data
          this.member = res.data[0]
          console.log(res.data)
        });
  },
};
</script> 
<style scoped lang="scss">

  .mobile-content-background{
    background-color:#F5F7FA;
    height:100vh;
    margin-top:150px;
  }
  .mobile-content{
    padding:16px 24px 0 24px;
    transform: translateY(-96px);
  }
  .info-box{
    background-color: white;
    border-radius: 5px;
    border: 1px solid #C9C9C9;
  }
  .info-box-list-single{
    margin: 24px;
  }
  .info-box-list-select{
    margin: 12px;
    border-bottom: 1px solid #C9C9C9;
  }
  .info-box-list-select > div{
    padding: 10px 0;
  }
  .info-box-list{
    margin: 12px;
    border-bottom: 1px solid #C9C9C9;
  }
  .info-box-list:last-child{
    margin-bottom: 0;
    border: none;
  }
  .info-box-list > div{
    padding-bottom: 10px;
  }
</style>