<template>
  <div class="page-background-alert">
    <div class="d-flex flex-column align-center justify-space-between pr-10 pl-10 " >
      <!-- <div style="margin-top: 100px;" class="mb-10">
        <img class="apaly-logo" src="/apalyhealthlogo.png" alt="">
      </div> -->

      <div class="mb-10">
        <div style="border-radius: 50%; background-color: #C9C9C9; width: 300px;height: 300px">
        </div>
      </div>

      <div class="d-flex flex-column align-center " >
        <span class="entry-title">Lorem ipsum dolor sit amet</span>
        <span class="text-center entry-description">
          With Apaly, you can...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
      </div>

      <div class="d-flex footer"  style="">
        <div class="col-6">
          <v-btn class="footer-btn-cancel" color="primary" outlined @click="$router.push({name:'MemberLogin'})"  >Login</v-btn>
        </div>
        <div class="col-6">
          <v-btn color="white" class="footer-btn" @click="$router.push({name:'MemberRegister'})"  >Register</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {},
  created() {},
};
</script>
<style scoped lang="scss">

.page-background-alert {
  height: 100vh;
  background-color:  white;
}

.apaly-logo{
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

.footer{
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn-cancel{
  border-radius: 23px;
  width: 100%;
  float:right
}

.footer-btn{
  background-color: #16407A !important;
  border-radius: 23px;
  width: 100%;
  color: white;
}
.entry-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.entry-description{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
</style>