<template>
    <div class="page-background">
      <main-header :title="'Family Members'"  :color="'#001E46'" :icon="'add'" @addMemberFromHeader="goToAddMember()"></main-header>
      <div class="mobile-content-background">
          <v-list>
            <v-list-item-group color="primary" >
              <v-list-item v-for="(member,i) in members" :key="i"  style="border-bottom:1px solid #00000029" @click="member.subscriberRelation == 'Self' ? $router.push({name:'accountFamilyMembers'}) :  $router.push(`/account/family-members/family-member/${member.id}`)"  >
                <v-list-item-content>
                  <v-list-item-title v-text="`${member.firstName} ${member.lastName}`"  class="list-item-title" ></v-list-item-title>
                  <v-list-item-subtitle v-if="member.subscriberRelation=='Self'"  v-text="'Primary Account Holder'" class="list-item-subtitle"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color=""  v-text="'mdi-chevron-right'" class="mr-3"></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item   style="border-bottom:1px solid #00000029"  @click="$router.push({name:'addFamilyMember'})" >
                <v-list-item-content>
                  <v-list-item-title v-text="'Add a family member'"  class="list-item-title" ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color=""  v-text="'mdi-plus'" class="mr-3"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";
export default {
  data() {
    return {
      members:[]
    };
  },
  components:{
    mainHeader
  },
  methods: {
    goToAddMember(){
      this.$router.push({name:'addFamilyMember'})
    }
  },
  created() {
    api().get(`/member/app/family/members`).then((res)=>{
      if(res){
        this.members = res.data
        console.log('res.data',res.data.length);
      }
    }).catch((err)=>{
      console.log('err',err)
    });
  },
};
</script>

<style scoped lang="scss">
  .mobile-content-background{
    background-color:white;
    height:100vh;
  }
  .mobile-content{
    padding:16px 24px 0 24px;
  }
  .page-background{
    background-color: #E8E8E8
;
  }
  .list-item-title{
    color:#0573F3;
    font-family: 'Quicksand';
    font-weight: 700;
  }

  .list-item-subtitle{
    font-family: 'Quicksand';
    font-weight: 600;
    color:red;
  }
</style>