<template>
  <div class="page-background">
    <main-header :title="'Edit Family Member'" :color="'white'"></main-header>
    <div class="mobile-edit-form">
      <v-form class="forma" v-model.trim="valid" ref="formEditMember">
        <div>
          <h3 class="from-headers">Family Member’s Information</h3>
          <v-text-field outlined label="First Name" dark class="input-text" v-model="member.firstName" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Last Name" dark v-model="member.lastName" :rules="requiredRules"></v-text-field>
          <v-text-field dark type="date" outlined label="Date of Birth" v-model="member.dob"></v-text-field>
          <v-select dark :items="genders" item-title="text" item-value="value" label="Gender" outlined v-model="member.gender" :rules="requiredRules"></v-select>
        </div>

        <div>
          <h3 class="from-headers">Contact</h3>
          <v-text-field type="email" outlined label="Email" dark v-model="member.email" :rules="emailRules"></v-text-field>
          <v-text-field type="tel" outlined label="Phone" placeholder="000-000-0000 " dark v-model="member.phone" :rules="requiredRules"></v-text-field>
          <v-text-field dark type="number" placeholder="00000" outlined label="Home Zip Code" v-model="member.zipCode"></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">
            Insurance Information <br />
            <span class="from-sub-headers">(from your insurance card)</span>
          </h3>

          <v-text-field outlined label="Member ID" dark placeholder="000-000000" v-model="member.memberId" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Group ID" placeholder="000-000000" dark v-model="member.groupId" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Payer ID" placeholder="000-000000" dark v-model="member.payerId" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Plan ID" placeholder="000-000000" dark v-model="member.planId" :rules="requiredRules"></v-text-field>
          <v-select dark :items="relationships" label="Select" outlined v-model="member.subscriberRelation"></v-select>
        </div>

        <div class="mb-6">
          <h3 class="from-headers">Provider <br /></h3>
          <div class="row">
            <div class="col-6 pt-0">
              <span class="provider-location">Provider</span> <br />
              <span class="from-sub-headers">Everside Health</span>
            </div>
            <div class="col-6 pt-0">
              <span class="provider-location">Location</span> <br />
              <span class="from-sub-headers">Main Street West</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-0">
              <v-btn class="btn-switch-providers" @click="$router.push({name:'FindCare'})" >Switch Providers</v-btn>
            </div>
          </div>
        </div>

        <div>
          <h3 class="from-headers">Family Member Management <br /></h3>
          <div class="row">
            <div class="col-12 pt-0">
              <v-btn class="btn-red-background mt-2" @click="removeDialog = true">Remove {{member.firstName}} {{ member.lastName }}</v-btn>
            </div>
          </div>
        </div>
      </v-form>
      <v-dialog v-model="saveDialog" width="500">
        <v-card>
          <v-icon class="icon-close-dialog" color="#16407A" v-text="'mdi-close'" @click="saveDialog = false"></v-icon>

          <v-card-title class="dialog-title">
            Would you like to save your changes first?
          </v-card-title>

          <v-card-text>
            <v-btn class="btn-white-background" style="border: 1px solid white"
                   @click="$router.push(`/account/family-members/family-member/${member._id}`)">
              Leave without saving
            </v-btn>
            <v-btn class="btn-blue-background" @click="submit(true)">Save and leave</v-btn>
          </v-card-text>

          <div></div>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeDialog" width="500">
        <v-card>
          <v-card-title class="remove-dialog-title">
            Are you sure you want to remove this family member from your
            account?
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-6">
                <v-btn class="btn-white-background" style="border: 1px solid white" @click="removeDialog = false">No, go back</v-btn>
              </div>

              <div class="col-6">
                <v-btn class="btn-red-background" style="border: 1px solid white" @click="deleteMember()">Remove</v-btn>
              </div>
            </div>
          </v-card-text>

          <div></div>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex footer" style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" @click="saveDialog = true">Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn color="white" class="footer-btn" @click="submit()">Save</v-btn>
      </div>
    </div>
    <v-snackbar
        v-model="snackbar"
        timeout="3000"
    >
      {{textInSnackbar}}
    </v-snackbar>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";
import moment from "moment";

export default {
  data() {
    return {
      id: this.$route.params.id,
      member: {
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        email: "",
        phone: "",
        zipCode: "",
        memberId: "",
        groupId: "",
        planId: "",
        payerId: "",
      },
      saveDialog: false,
      removeDialog: false,
      relationships: ["Spouse"],
      genders: [
        {
          value: "m",
          text: "Male",
        },
        {
          value: "f",
          text: "Female",
        },
      ],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      snackbar:false,
      textInSnackbar:''
    };
  },
  components: {
    mainHeader,
  },
  created() {
    this.getMember();
  },
  methods: {
    getMember() {
      api()
          .get(`/member/app/family/member/${this.id}`)
          .then((res) => {
            if (res) {
              this.member = res.data;
              this.member.dob = moment.utc(res.data.dob).format("YYYY-MM-DD");
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
    },
    submit(leavePage = false){
      this.$refs.formEditMember.validate();
      if (this.valid){
        let data = {
          firstName:this.member.firstName,
          lastName:this.member.lastName,
          email:this.member.email,
          gender:this.member.gender,
          zipCode:this.member.zipCode,
          phone:this.member.phone,
          dob:this.member.dob,
        }
        api().put(`/member/app/family/member/${this.member._id}`, data)
            .then((res) => {
              if(leavePage){
                this.$router.push(`/account/family-members/family-member/${this.member._id}`)
              }else{
                this.$router.push('/account/alert/update');
              }
            }).catch((err)=>{
          console.log("err from update", err);
        });
      }
    },
    deleteMember(){
      api().delete(`/member/app/family/member/${this.member._id}`)
          .then((res)=> {
            this.$router.push({name:'familyMembersMenu'});
          }).catch((err)=>{
        this.removeDialog = false;
        this.snackbar = true;
        this.textInSnackbar = err.response.data
      });
    }
  },
};
</script>
<style scoped lang="scss">
.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 190px !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {
  background-color: #001e46;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.footer {
  background-color: #16407a;
  height: 80px;
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: flex-start;
  border-top: 1px solid #c9c9c9;
}
.footer-btn {
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel {
  background-color: #16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float: right;
}
.btn-switch-providers {
  background-color: #16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float: right;
}
// ::-webkit-scrollbar {
//   display: none;
// }

.btn-red-background {
  background-color: #c24141 !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float: right;
}
.provider-location {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #c9c9c9;
}

.dialog-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #16407a;
  padding-right: 70px !important;
  padding-left: 70px !important;
  //padding-top: 30px !important;
  text-align: center;
}

.btn-white-background {
  background-color: white !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid #16407a !important;
  color: #16407a;
  float: right;
  margin-bottom: 10px;
}

.btn-blue-background {
  background-color: #16407a !important;
  border-radius: 23px;
  width: 100%;
  color: white;
  float: right;
}

.icon-close-dialog::before {
  position: absolute;
  left: 300px;
  top: 0px;
}

.remove-dialog-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #16407a;
  //padding-right: 70px !important;
  //padding-left: 70px !important;
  //padding-top: 30px !important;
  text-align: center;
}
</style>
