<template>
    <div>

    </div>

</template>
<script>
export default {
    props:{
        confirmInformationModal:{
        required:true
    }
},
  data() {
    return {

    };
  },
  methods:{

  }
};
</script>
