<template>
    <div class="mt-6" >
      <div class="pa-4 mt-0 mb-1 d-flex">
        <div class="font-24">
          My Solutions 
        </div>
      </div>
      <div v-if="mySolutions.solutions && mySolutions.solutions.length >= 1">
        <div>
          <!-- <v-card-title class="text-gray font-weight-bold mb-0 mt-0 pt-0 pb-0">
            {{ item.category }}
          </v-card-title> -->
          <v-slide-group
            class="pa-4 pt-0 pb-1"
            active-class="success"
            show-arrows
          >
            <v-slide-item
              v-for="(card, index1) in mySolutions.solutions"
              :key="index1"
            >
              <v-card
                height="220px"
                width="180px"
                class="pa-1 mr-1 mb-2 mt-1 cursor-pointer box-hover"
              >
                <div
                  class="pl-1 pr-1 pb-0"
                  @click="goToDetails(card, card.entity)"
                >
                <div
                v-if="card.logo"
                class="text-start justify-start mb-1 mt-0"
                style="height: 80px; overflow: hidden; background-size: contain;"
                :style="`background-image:url(${card.logo})`"
              ></div>
              <div
                v-else
                class="text-start justify-start mb-1 mt-0"
                style="height: 80px; overflow: hidden; background-size: contain;"
                :style="`background-image:url(${returnCardImage(card.entity)})`"
              ></div>
                  <span class="text-start mt-0 text-ellipsis">
                    <p class="font-14 font-weight-500 mb-1 mt-0">
                      {{ card.name }}
                    </p>
                  </span>
                  <span class="text-start text-ellipsis">
                    <p class="font-12 mb-1 font-weight-400">
                      {{ card.address }}, {{ card.city }}, {{ card.state }},
                      {{ card.zipCode }}
                    </p>
                  </span>
                  <div class="justify-start">
                    <v-chip
                      v-if="card.entity == 'solution'"
                      class="pa-2"
                      color="#AAC9F2"
                      text-color="#333333"
                      small
                    >
                      Virtual Network
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'vendor'"
                      class="pa-2"
                      color="#FFE2CC"
                      text-color="#333333"
                      small
                    >
                      Point Solution Vendor
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'location'"
                      class="pa-2"
                      color="#FFF4BF"
                      text-color="#333333"
                      small
                    >
                      Clinic & Organization
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'provider'"
                      class="pa-2"
                      color="#CDFFCC"
                      text-color="#333333"
                      small
                    >
                      Clinician
                    </v-chip>
                  </div>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
      <div v-else  class="col-12">
        <v-alert
          border="bottom"
          colored-border
          color="primary"
          elevation="2"
          class="col-12 text-gray mt-2 ml-2"
        >
          You are not paricipating in any solution!
        </v-alert>
      </div>
      <v-divider horizontal class="pt-1 pb-1"> </v-divider>
  
      <div >
        <div class="pa-4 mt-0 mb-1 d-flex">
          <div class="font-24">
            My Favorites <img src="/svg/filled-heart.svg" height="18px" />
          </div>
        </div>
        <div v-if="mySolutions.favorites && mySolutions.favorites.length < 1" class="col-12 ma-2">
          <v-alert
            border="bottom"
            colored-border
            color="primary"
            elevation="2"
            class="col-12 text-gray mt-2"
          >
            There are no solutions marked as favorites!
          </v-alert>
        </div>
        <div v-else>   
           <div >
          <v-card-title
            class="text-gray font-weight-bold mb-3 mt-0 pt-0 pb-0"
          >
          
          </v-card-title>
          <v-slide-group
            class="pa-4 pt-0 pb-2"
            active-class="success"
            show-arrows
          >
            <v-slide-item
              v-for="(card, index1) in  mySolutions.favorites"
              :key="index1"
            >
              <v-card
                height="215px"
                width="180px"
                class="pa-1 mr-2 mb-1 mt-1 cursor-pointer box-hover"
              >
                <div
                  class="pl-1 pr-1 pb-0"
                  @click="goToDetails(card, card.entity)"
                >
                <div
                v-if="card.logo"
                class="text-start justify-start mb-1 mt-0"
                style="height: 80px; overflow: hidden; background-size: contain;"
                :style="`background-image:url(${card.logo})`"
              ></div>
              <div
                v-else
                class="text-start justify-start mb-1 mt-0"
                style="height: 80px; overflow: hidden; background-size: contain;"
                :style="`background-image:url(${returnCardImage(card)})`"
              ></div>
                  <span class="text-start mt-0 text-ellipsis">
                    <p class="font-14 font-weight-500 mb-1 mt-0">
                      {{ card.name }}
                    </p>
                  </span>
                  <span class="text-start text-ellipsis">
                    <p class="font-12 font-weight-400 mb-1 text-gray">
                      {{ card.address }}, {{ card.city }}, {{ card.state }},
                      {{ card.zipCode }}
                    </p>
                  </span>
                  <div class="justify-start">
                    <v-chip
                      v-if="card.entity == 'solution'"
                      class="pa-2"
                      color="#AAC9F2"
                      text-color="#333333"
                      small
                    >
                      Virtual Network
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'vendor'"
                      class="pa-2"
                      color="#FFE2CC"
                      text-color="#333333"
                      small
                    >
                      Point Solution Vendor
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'location'"
                      class="pa-2"
                      color="#FFF4BF"
                      text-color="#333333"
                      small
                    >
                      Clinic & Organization
                    </v-chip>
                    <v-chip
                      v-if="card.entity == 'provider'"
                      class="pa-2"
                      color="#CDFFCC"
                      text-color="#333333"
                      small
                    >
                      Clinician
                    </v-chip>
                  </div>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <v-divider horizontal class="mb-2"></v-divider>
        </div></div>
     
      </div>
       </div>
   
  </template>
  <script>
  var _paq = window._paq || [];
  _paq.push(['trackPageView']);
  import api from "../../../../services/api";
  export default {
    data() {
      return {
        mySolutions: [],
      };
    },
    
    created() {
      this.getMySolutions();
    },
    beforeRouteUpdate(to, from, next) {
      this.getMarketplace();
      next();
    },
  
    computed: {
    
    },
    methods: {
        changeTab(tab) {
      this.tab = tab;
    },
    getMySolutions() {
     api()
       .get('/member/app/solutions')
       .then((res) => {
         this.mySolutions = res.data;
       });
   },
   returnCardImage(card) {
    console.log('card',card)
      if (card.logo) {
        return card.logo;
      } else {
        if (card.entity == "location" || card == "location") {
          return "/tpa/clinicsAndOrganizations.svg";
        } else if (card.entity == "vendor") {
          return "/tpa/pointSolutionVendor.svg";
        } else if (card.entity == "provider") {
          if (card.gender == "F") {
            return "/svg/clinicianCard.svg";
          } else if (card.gender == "M") {
            return "/svg/clinicianCardMale.svg";
          }
        }
      }
    },
    goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/marketplace/solutions/details/${item.id}`);
      } else if (entity == "location") {
        this.$store.state.documentTitle = item.name;
        this.$router.push({
          path: `/marketplace/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/marketplace/vendor/details/${item.id}`);
      } else if (entity == "provider") {
        // this.$router.push(`/marketplace/clinician/details/${item.id}`);
      }
      this.showOverlay = false;
    },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .text-ellipsis {
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  </style>
  