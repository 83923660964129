<template>
  <div class="page-background">
    <main-header :title="'Login'" :color="'white'" ></main-header>
    <div class="page-background-alert">
      <div class="d-flex flex-column align-center justify-space-between pr-5 pl-5 " >
        <!-- <div style="margin-top: 100px;margin-bottom: 80px !important;">
          <img class="apaly-logo" src="/apaly.png" alt="">
        </div> -->
        <div class="d-flex flex-column align-center" style="width: 100%" >

          <v-form style="width: 100%" v-model.trim="valid" ref="form">
            <div>
              <h3 class="form-title">Change Password</h3>
              <span style="color: white">Requirements:</span>
              <ul class="list-class mb-4"  >
                <li>At least 8 characters</li>
                <li>At least 1 capital letter</li>
                <li>At least 1 lowercase letter</li>
                <li> At least 1 special character - ! @ # $ % etc</li>
              </ul>
<!--              <v-list >-->
<!--                <v-list-item-->
<!--                    v-for="item in items"-->
<!--                    :title="item"-->
<!--                ></v-list-item>-->
<!--              </v-list>-->
              <v-text-field outlined label="New password" dark class="input-text" :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="newPassword" :rules="passwordRules"
              ></v-text-field>
              <v-text-field outlined label="Confirm new password" dark class="input-text" :type="show2 ? 'text' : 'password'" @blur="checkPassword"
                            @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" v-model="confirmationPassword" :messages="!passwordMatches ? 'Passwords do not match.' : ''"
              ></v-text-field>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" @click="$router.push({name:'MemberLogin'})" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn class="footer-btn" @click="changePassword" :disabled="!passwordMatches">Change Password</v-btn>
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{textInSnackbar}}
    </v-snackbar>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";

export default {
  data() {
    return {
      show1:false,
      show2:false,
      token:this.$route.query.token,
      newPassword:'',
      confirmationPassword:'',
      snackbar:false,
      textInSnackbar:'',
      passwordMatches:true,
      valid: false,
      passwordRules: [
        (v) => !!v || "This field is required",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(v) || "Min. 8 characters with at least one capital letter, a number and a special character.",
      ],
    };
  },
  components: {
    mainHeader,
  },
  methods: {
    checkPassword(){
      if (this.newPassword !== this.confirmationPassword){
        this.passwordMatches = false;
      }else {
        this.passwordMatches = true;
      }
    },

    changePassword(){
      this.$refs.form.validate();
      if (this.valid){
        const data={
        'password':this.newPassword
      };
        const config = {
          headers: {"x-access-token": this.token}
        };
        api().post(`/member/app/set/password`,data,config).then((res)=>{
          if(res){
            this.$router.push('/login')
          }
        }).catch((err)=>{
          this.snackbar = true;
          this.textInSnackbar=err.response.data;
          console.log('err',err)
        });
      }
    }
  },
  created() {},
};
</script>
<style scoped lang="scss">

.page-background {

  background-color: #001e46;
}

.page-background-alert {
  height: 100vh;
  background-color:  #16407A;
}

.apaly-logo{
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
;
}

.form-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

.footer{
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
.footer-btn{
  background-color: white !important;
  border-radius: 23px;
  width: 100%;
  color: #16407A ;
}
.entry-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.entry-description{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.above-footer-text{
  margin-top: 170px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-left: 17px;
  color: #C9C9C9;
}

.list-class{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color:white;
}
</style>