<template>
  <div class=" register-page  ">
   <div  class="register-page-container mt-2">
      <div class="register-page-form  d-md-flex mt-0">
        <div class="register-page-logo  mr-auto">
          <!-- <img  src="/svg/backArrow.svg" height="32" role="button" @click="$router.go(-1)"><br> -->
          <img class="apaly-logo  hide-desktop"  src="/apaly-logo.png" height="64" alt="">
        </div>  
    <v-form ref="form" v-model.trim="valid"    class="authentication-page-right-side-form">
      <div>
        <div class="col-12">
          <span class="mt-5">
            <p style="font-size: 32px">Reset Password</p>
          </span>
        </div>
        <div  class="col-12 no-list-style">
          Requirements:
          <ul class="ml-0 pl-0 font-14">
            <li :class="isLengthValid ? 'valid-text text-decoration-none' : ''">
              <v-icon v-if="isLengthValid" size="small" color="green">mdi-check</v-icon>
              <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
              At least 8 characters</li>
            <li :class="isCapitalLetterValid ? 'valid-text' : ''">
              <v-icon v-if="isCapitalLetterValid" size="small" color="green">mdi-check</v-icon>
              <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
              At least one upper case letter</li>
            <li :class="isLowercaseLetterValid ? 'valid-text' : ''">
              <v-icon v-if="isLowercaseLetterValid" size="small" color="green">mdi-check</v-icon>
              <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
              At least 1 lowercase letter</li>
            <li :class="isSpecialCharacterValid ? 'valid-text' : ''">
              <v-icon v-if="isSpecialCharacterValid" size="small" color="green">mdi-check</v-icon>
              <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
              At least 1 special character - ! @ # $ % etc</li>
          </ul>
        </div>

        <div class="col-12">
          <label for="" class="tiny-font">New Password</label>
          <v-text-field
            outlined
            v-model.trim="password"
            required
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            style="border-radius: 8px;"
            :rules="passRules"
            @input="confirmPass()"
          ></v-text-field>
        </div>

        <div class="col-12 mb-0 pb-0 mt-0 pt-0">
          <label for="" class="tiny-font"> Confirm New Password</label>
          <v-text-field
            outlined
            v-model.trim="confirmpassword"
            required
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="confirmPassRules"
            style="border-radius: 8px;"
            @input="confirmPass()"
          ></v-text-field>
        </div>
        <v-col v-if="error.length > 0" class="mt-3 mb-3 col-md-12">
          <v-alert dense outlined type="error">
            {{ error }}
          </v-alert>
        </v-col>

        <!-- <div class="col-12 d-flex" style="justify-content:space-between">
          <v-btn color="#0069F3" text @click="$router.push('/login')">Back to Login</v-btn>
          <v-btn color="#0069F3" :disabled="!valid" style="border-radius: 8px;" width="300" class="text-white" @click="send()">
            Reset password
          </v-btn>
        </div> -->

        <div class="dialog_buttons reset_pass_btn_container">
          <button class="dialog_double_button dialog_btn_second_4 dialog_btn_floatLeft reset_pass_btn" @click="$router.push('/login')" >
            Back to Login
          </button>
          <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight reset_pass_btn" :disabled="!valid" @click.prevent="send()" >
            Reset password
          </button>
        </div>

      </div>
    </v-form>
    <img class="apaly-logo hide-mobile ml-auto" src="/apaly-logo.png" height="64">

    </div>
   </div>
  
   <v-dialog v-model.trim="rightPass" width="500" class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="backToLogin()">mdi-close</v-icon></div>
          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0" style="color:#014D00">
              Password saved successfully!
            </span>
            <br>
            <span class="text-start font-16 mt-2 font-weight-300  mb-5">
              Please continue to login.
            </span>
            <div class="text-center text--white mt-6">
              <v-btn color="#0069F3" class="done-btn" @click="backToLogin()">
                Continue to Login
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="alreadyHavePasswordModal" width="500" persistent class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="backToLogin()">mdi-close</v-icon></div>
          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0" style="color:#014D00">
              Success. Your e-mail is confirmed.
            </span>
            <div class="text-center text--white mt-6">
              <v-btn color="#0069F3" class="done-btn" @click="backToLogin()">
                Continue to Login
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" ref="error_modal" ></errorModal>

  </div>
</template>
<script>
import Vue from "vue";
import api from "@/services/api";
import errorModal from '../../../Common/SomethingWentWrong.vue'
import { mapActions } from "vuex";
export default {
  data() {
    return {   
         somethingWrong: false,
      alreadyHavePasswordModal: false,
      error: "",
      checkbox: false,
      pdfDialog: false,
      showContract: false,
      tokenAcc: "",
      confirmed: false,
      wrongPass: false,
      rightPass: false,
      email: "",
      changePassEmail: "",
      check1: false,
      check2: false,
      check3: false,
      contains_uppercase: false,
      contains_special_character: false,
      password: "",
      confirmpassword: "",
      valid: false,
      show: false,
      show1: false,
      error: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Password does not meet the security requirements",
        (v) => (v && /[A-Z]{1}/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[a-z]/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "Password does not meet the security requirements",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Password does not meet the security requirements",
        (v) => (v && /[A-Z]{1}/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[a-z]/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "Password does not meet the security requirements",
        (v) => v == this.password || "Password must match",
      ],
      passsError: "",
      error: "",
      newToken: null
    };
  },
  components: {
    errorModal
  },
  computed: {
    token() {
      return this.$route.params;
    },
    isLengthValid() {
      return this.password.length >= 8;
    },
    isCapitalLetterValid() {
      return /[A-Z]/.test(this.password);
    },
    isLowercaseLetterValid() {
      return /[a-z]/.test(this.password);
    },
    isSpecialCharacterValid() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
  },
  created() {
    this.tokenAcc = this.token;
    if (this.$route.query.token) {
      api()
              .post(`/member/app/validate/token`, {
                token: this.$route.query.token,
              })
              .catch((err) => {
                if(err.response.status == 401){
                  window.location = '/expired'       
               }
              })
              .then((res) => {
                if(res){
                    this.newToken = res.data.newToken
                }
              });
    }
  },
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    reason() {
      this.reasonDialog = true;
    },
    openPdfModal() {
      const url =
        "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads//admin/contracts/terms.pdf";
      this.pdfUrl = url;
      this.pdfDialog = true;
    },
    send() {
      if (this.$route.query.token) {
            api()
              .post(`/member/app/password/reset`, {
                password: this.password,
                token: this.newToken,
              })
              .catch((err) => {
                if(err.response.status == 403){
               this.somethingWrong = true;   
               }
              })
              .then((res) => {
                if(res){
                  console.log('res',res)
                   this.rightPass = true;
                }
               
              });
          }
    },
    backToLogin() {
      this.$router.push("/login");
    },
    checkPassword() {
      if (this.password.length >= 8) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
      this.contains_uppercase = /[A-Z]/.test(this.password);

      if (this.contains_uppercase == true) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.contains_special_character = format.test(this.password);
      if (this.contains_special_character == true) {
        this.check3 = true;
      } else {
        this.check3 = false;
      }
    },
    confirmPass() {
      const updatedRules = [...this.confirmPassRules];
      if (this.confirmpassword !== this.password) {
        updatedRules[6] = "Password must match";
    } else {
      updatedRules[6] = true;
    }
    this.confirmPassRules = updatedRules;
     this.somethingWrong = false;
    },
  },
};
</script>
<style scoped>
.hide-desktop{
  display: none !important;
}
label{
    color: #333333 !important;
    font-weight: 400; 
  }
.no-list-style li {
  list-style-type: none !important;
}
.register-page {
  padding: 64px;
  padding-top: 20px;
}
.apaly-logo {
  width: 130px;
}
.register-page-container {
  margin: 0 auto;
}
.register-page-logo {
  left: 30px;
}
.register-page-title {
  text-align: center;
}
.apaly-color {
  color: #16407a;
}
.display {
  display: grid;
}
.yes-btn {
  color: white;
}
.submit-btn {
  color: white;
}
.valid-text {
  color: green;
}

.confirm-btn {
  color: white;
  width: inherit;
}
.done-btn {
  color: white;
  width: 170px;
}
.org {
  margin-top: 20vh;
}
.no-btn {
  width: 170px;
  border: 1px solid #16407a;
}
.color-black {
  color: black;
}
.desc {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.authentication-page-right-side-form {
  width: 50%;
  align-self: center;
  padding-left: 50px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .apaly-logo{
    height: 64px;
    width: 120px;
  }
  .hide-desktop{
  display: flex !important;
}
  .hide-mobile{
    display: none !important;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .register-page-logo {
    display: flex;
    justify-content: center;
     padding-top: 8px;
  }
  .register-page {
    padding: 24px;
  }
  .register-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .register-page-container {
    width: 100%;
  }
  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .register-page-error-box {
    padding: 10px 0;
  }
  .authentication-page-right-side-form {
    width:100%;
    padding: 0;
  }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
